import cn from 'classnames';

import type { TPromoBannerBillboardProps, TPromoBannerCtaLink, TWithOnClick } from '@/features/promoBanners/types';
import type { TLink } from '@/infra/types/common';

import ImageSet from '@/components/Media/ImageSet';

import CTA from '../CTA';
import Eyebrow from '../Eyebrow';
import verifyValue from '../helpers/verifyValue';

import styles from './PromoBannerBillboard.module.scss';

export const APPEARANCES = ['standard', 'adaLockup'];
export const CONTENT_ALIGNS = ['left', 'right'];
export const THEMES = ['lightMode', 'darkMode'];
export const OVERLAY_COLORS = ['virginRed', 'rockstar', 'uplift', 'squidInkLight', 'oceanBlue'];

const PromoBannerBillboard = ({
  appearance,
  contentAlign,
  cta,
  description,
  eyebrow,
  imageSet,
  onClick,
  overlayColor,
  theme,
  title,
}: TWithOnClick<TPromoBannerBillboardProps>) => {
  const hasImage = !!imageSet?.srcSet;
  const hasCTA = !!cta?.url && !!cta?.label;
  const rootClasses = [
    `_appearance-${verifyValue(appearance, APPEARANCES)}`,
    `_align-${verifyValue(contentAlign, CONTENT_ALIGNS)}`,
    `_theme-${verifyValue(theme, THEMES)}`,
    `_overlay-${verifyValue(overlayColor, OVERLAY_COLORS)}`,
  ];

  // Temporary commented for MSH-123191
  // if (!hasImage) return;

  return (
    <div className={cn(styles.root, ...rootClasses)}>
      {hasImage && <ImageSet className={styles.picture} {...imageSet} />}
      <div className={styles.content}>
        {!!eyebrow && (
          <div className={styles.eyebrowBox}>
            <Eyebrow className={styles.eyebrow} {...eyebrow} isOmitPastCountdown />
          </div>
        )}
        <div className={styles.inner}>
          <div className={styles.textual}>
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>{description}</div>
          </div>
          {hasCTA && <CTA className={styles.cta} onClick={onClick} {...(cta as TLink & TPromoBannerCtaLink)} />}
        </div>
      </div>
    </div>
  );
};

export default PromoBannerBillboard;
