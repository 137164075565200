import Price from '@/components/elements/Price';
import { getAmountPerItem, getAmountPerVoyage } from '@/helpers/data/mappers/Summary';
import { FiltersPriceType } from '@/infra/types/common/filters';

type Props = {
  abTestPriceType?: string;
  cabins?: number;
  currencyCode?: string;
  idForAriaLabelSafari?: string;
  isForceLineBreak?: boolean;
  isFromAdvFilter?: boolean;
  isVariantBAndAmount?: boolean;
  isVariantBAndDiscount?: boolean;
  sailors?: number;
  startingPrice?: Record<string, unknown>;
};

const CabinPrice = ({
  abTestPriceType = '',
  cabins = 1,
  currencyCode = 'USD',
  descriptionPrefix = '',
  duration,
  hideOriginalAmount,
  idForAriaLabelSafari,
  isForceLineBreak = true,
  isFromAdvFilter,
  isVariantBAndAmount,
  isVariantBAndDiscount,
  sailors,
  startingPrice = {},
  strikethroughValue,
}: Props) => {
  const priceType =
    abTestPriceType === FiltersPriceType.sailorPerNight && isFromAdvFilter
      ? FiltersPriceType.perCabin
      : abTestPriceType;
  const price =
    abTestPriceType === FiltersPriceType.sailorPerNight && !isFromAdvFilter && !isVariantBAndDiscount
      ? getAmountPerVoyage(startingPrice, duration, { sailors })
      : getAmountPerItem(startingPrice, { cabins, priceType, sailors });
  let originalAmount =
    abTestPriceType === FiltersPriceType.sailorPerNight && !isFromAdvFilter && !isVariantBAndDiscount
      ? getAmountPerVoyage(startingPrice, duration, { sailors }, 'originalAmount')
      : getAmountPerItem(startingPrice, { cabins, priceType, sailors }, 'originalAmount');

  if (strikethroughValue) {
    originalAmount += strikethroughValue;
  }

  return (
    <Price
      amount={price}
      currencyCode={currencyCode}
      descriptionPrefix={descriptionPrefix}
      idForAriaLabelSafari={idForAriaLabelSafari}
      isForceLineBreak={isForceLineBreak}
      isVariantBAndAmount={isVariantBAndAmount}
      isVariantBAndDiscount={isVariantBAndDiscount}
      originalAmount={!hideOriginalAmount && originalAmount}
      roundUp
    />
  );
};

export default CabinPrice;
